import * as config from "./config.js";
import * as utils from "./utilities.js";
import _ from 'lodash';

import lang from "./ui_lang.js";
import * as api from "./api_conn.js";

import baseLayout from 'bundle-text:../views/content_layout_base.tpl';
import layoutWidgetCard from 'bundle-text:../views/content_layout_widget_card.tpl';

import * as layoutControl from "./layoutControl.js";
import * as widgetControl from "./widgetControl.js";

export  {
     setup,
     refresh
};

let menu_context = {};   // a list of all displayable verbs for current user (only updated by getAvaliableVerbs)

// optional, rearrange the order of the menu group
let menu_order = layoutControl.menu_order;

// optional, just overrider default itlte if any
let menu_items_settings = layoutControl.menu_item_settings;


function refresh(){
     setup();
}

function setup(){

     var userinfo = utils.getItem("userinfo");
     var userData = {};

     if (userinfo) {
          userData = userinfo.userinfo
     }

     // add group names
     userData.groupnames = $.map(userData.groups, function(a) {
          return a.title;
     }).join(", ");

     pjDataPaster(userData);

     getAvailableVerbs(function(res){
          buildMainMenu();
     });

     // register event listener
     utils.registerPJEvent({          
          onSystemStatusChange : onSystemStatusChange
     });

}


function onSystemStatusChange(res) {

     utils.setItem("SYSTEM_LOCK", res.locked);     
     utils.loaderBlock(res.locked);
     
}

//   rev 22.06.14
//   setup menu base on menu_context
function buildMainMenu(){

     var pool = $("#pj-mainmenu");

     var item_tpl        = `<li class="nav-item"><a href="#" class="nav-link"></a></li>`
     var item_folder     = `<li class="nav-item nav-item-submenu">
                              <a href="#" class="nav-link"><i></i><span></span></a>
                              <ul class="nav nav-group-sub" data-submenu-title></ul></li>`;

     var root_menu = $('<div></div>');

     if (menu_context) {

          var menuCtx = _.clone(menu_context);

          // add dashboard main item to menu
          var dashboard_menuitem = {
               dashboard : {
                    icon : "icon-home4",
                    title : `${lang.menu_dashboardTitle}`,
                    options : "noquery"
               }
          }
          _.merge(dashboard_menuitem, menuCtx["root"]);
          menuCtx["root"] = dashboard_menuitem;

          // clear exiting
          pool.find("li").not(".dont-remove").remove();

          var newitem, newfolder, target, tpl, txt;

          for(var groupid in menuCtx){
               var p = menuCtx[groupid];

               if (groupid.substr(0,1) !== "_" ) {

                    newfolder = $(item_folder).clone();
                    newfolder.find("span").html(groupid);
                    newfolder.find("ul").attr("data-submenu-title", groupid);
                    
                    var pp = menuCtx[groupid];
                    var icon_pix = "icon-stack2";

                    if (pp.__ && pp.__.icon) {
                         icon_pix = pp.__.icon;                         
                    } 
                    newfolder.find("i").addClass(icon_pix);                   


                    for(var verb in p){

                         var d = p[verb];
                         var menuTitle = d.title;
                         var disabled = d.disabled || 0;

                         if (!disabled && d.menu) {

                              if (d.icon) {
                                   txt = `<i class="${d.icon}"></i><span>${menuTitle}</span>`;
                              } else {
                                   if (groupid =="root") {
                                        txt = `<i class="icon-period"></i><span>${menuTitle}</span>`;
                                   } else {
                                        txt = menuTitle;
                                   }
                              }

                              newitem = $(item_tpl).clone();
                              newitem.find(".nav-link")
                                   .addClass("pj-menu-action")
                                   .attr("pj-verb",verb)
                                   .html(txt);

                              if (d.options) {
                                   newitem.find(".pj-menu-action")
                                   .attr("pj-options", d.options);
                              }

                              if (groupid == "root") {
                                   root_menu.append(newitem);
                              } else {
                                   newfolder.find("ul").append(newitem);
                                   pool.append(newfolder);
                              }
                         }
                    }
               }
          }

          pool.prepend(root_menu[0].children);

          // append version
          pool.append(`<li class="nav-item"><div class="nav-link text-muted line-height-base font-size-xs"><i></i><span style="line-height:1rem; opacity:0.25;">${config.appVersion} / ${config.appEnv}</span></div></li>`);

          initWidgetsMenu();
          initNavMainMenu();
          initMenuActions();
     }
}

//   initialize any widget consists of context menu,
function initWidgetsMenu() {

     widgetControl.refresh("currentUserMenu", menu_context["_1"]);
}


// extract from app.js
//   initalize main menu Accordion
//
function initNavMainMenu() {

    // Define default class names and options
    var navClass = 'nav-sidebar',
       navItemClass = 'nav-item',
       navItemOpenClass = 'nav-item-open',
       navLinkClass = 'nav-link',
       navSubmenuClass = 'nav-group-sub',
       navScrollSpyClass = 'nav-scrollspy',
       navSlidingSpeed = 250;

    // Configure collapsible functionality
    $('.' + navClass + ':not(.' + navScrollSpyClass + ')').each(function() {
       $(this).find('.' + navItemClass).has('.' + navSubmenuClass).children('.' + navItemClass + ' > ' + '.' + navLinkClass).not('.disabled').on('click', function (e) {
            e.preventDefault();

            // Simplify stuff
            var $target = $(this);

            // Collapsible
            if($target.parent('.' + navItemClass).hasClass(navItemOpenClass)) {
                $target.parent('.' + navItemClass).removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
            }
            else {
                $target.parent('.' + navItemClass).addClass(navItemOpenClass).children('.' + navSubmenuClass).slideDown(navSlidingSpeed);
            }

            // Accordion
            if ($target.parents('.' + navClass).data('nav-type') == 'accordion') {
                $target.parent('.' + navItemClass).siblings(':has(.' + navSubmenuClass + ')').removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
            }
       });
    });

    // Disable click in disabled navigation items
    $(document).on('click', '.' + navClass + ' .disabled', function(e) {
       e.preventDefault();
    });
}


//   rev 22.06.22
function initMenuActions(){

     // bind pj-menu-action event
    $(".pj-menu-action").unbind("click").bind("click", function(e){
         var verb = $(this).attr("pj-verb");
         var options = $(this).attr("pj-options"); // tbc

         var payload = {};

        utils.loaderBlock(true);

         if (options == undefined || options.search("noquery") < 0) {
              api.doQuery(verb, payload, function(res){
                   layoutControl.render(verb, res);
             });
          } else {
               layoutControl.render(verb, {});

          }
    });
}



// rev 22.06.21
// re ordering the sequence of the menu and apply item settings
function arrangeMenuOrderSettings(menu_ctx) {


     var out = {};
     var usermenu = {};

     if (menu_order) {
          for (var groupid in menu_order) {
               if (menu_order[groupid].disabled == undefined || menu_order[groupid].disabled !==1){
                    if (menu_ctx[groupid]) {
                         var m = menu_order[groupid];
                         
                         out[m.title] = menu_ctx[groupid];
                         out[m.title]["__"] = m;            // raw config

                    }
               }
          }
          out["root"] = menu_ctx["root"]
     } else {
          out = menu_ctx;
     }

     // menu position and settings
     $.each(out, (idx, elm) => {
          $.each(elm, (verb, e) => {
               if (menu_items_settings[verb]) {
                    _.merge(out[idx][verb], menu_items_settings[verb]);
               }

               // check menu position
               switch (out[idx][verb].locationID) {
                    case 1:   // usermenu
                         usermenu[verb] = out[idx][verb];
                         delete out[idx][verb];
                         break;
                    default:
               }

          })
     })

     if (Object.keys(usermenu).length > 0) {
          out._1 = usermenu;
     }

     return out;
}


//   rev 22.07.02
//   rev 22.06.14
//   get available verbs for current user
//
function getAvailableVerbs(cbfunc){

     var manifest = {};

     api.doQuery("listAllVerbs", {mode : "list"}, function(d){

          if (d.result) {

               // save all available verbs for this user to local
               $(window).data("_allVerbs", d.data);

               var verblist = d.data;
               $.each(verblist, function(verb, p){
                    if (p.display !== undefined) {
                         if (manifest[p.display.group] == undefined) {
                              manifest[p.display.group] = {};
                         }
                         manifest[p.display.group][verb] = p.display;
                    }
               });

               menu_context = arrangeMenuOrderSettings(manifest);
          }

           // utils.log(manifest);

          if (cbfunc) {
               cbfunc(manifest);
          }
     })

}


//   rev 22.06.14
//   paste data for pj-data class with payload given
//   scope : .pj-data,
//   field : pj-payload={fieldname}
//
function pjDataPaster(userData){
     var d= userData || {};

     // utils.log("paste", d);
     $.each(d, function(k, v){
          var b = $(".pj-data[pj-payload="+k+"]");
          if (b.length > 0) {
               b.html(v);
          }
     });
}
