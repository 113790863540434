//   rev 22.06.16
//   layout control
//
//   layoutComposer() > renderLayoutWidget()
//                    > render()
//
//

import * as config from "./config.js";
import * as utils from "./utilities.js";
import _ from 'lodash';

import lang from "./ui_lang.js";

import {getPageManifest, page_manifest_item_tpl, layoutTemplates} from "./page_manifest.js"; // a mapper of which page should goes base on verb
import {menu_order, menu_item_settings} from "./menu_manifest.js";

export {
     render,
     renderLayoutWidget,
     menu_order,
     menu_item_settings,
     renderPJelements,
     routePageView
}

const pjLayoutContentID = ".pj-layout[pj-payload=contents]"

//   helper function
//   merging information to given layout
//   apply to : layout, widget
//   context : array
function layoutComposer(context, className, layout) {

     var tpl = $(layout);

     // paste data to pj-layout
     for(var cls in context) {

          var ctx = context[cls];


          if (ctx === false) {
               tpl.find("." + className + "[pj-payload="+cls+"]").remove();
          } else {

               tpl.find("." + className + "[pj-payload="+cls+"]").html(ctx);

          }

     }

     return tpl;
}



function renderWindow(url) {

     window.open(url);
     return false;

}

function renderLayoutWidget(context, layoutwidget){
     return layoutComposer(context, "pj-widget", layoutwidget);
}


//   rev 22.06.21
//   replace all <pj-textbox> to html form
//
function renderPJelements(container){

     const elementTypes = {
          "pj-ui-text" : {    // call lang text label
               layout : (params) => {
                    return `${lang[params.label]}`;
               }
          },
          "pj-text" : {    // display data for display only
               layout : (params) => {

                    let helperText = "";
                    let displaytype = params.type || "text";

                    if (params.helper) {
                         helperText = `<span class="form-text text-muted font-size-sm">${params.helper}</span>`;
                    }
          
                    var lbl = lang[params.label] || params.label;

                    switch (displaytype) {
                         case "plain":
                              return `<div class="pj-form-data font-size-lg font-weight-semibold" payload="${params.field}"></div>
                                   ${helperText}`;                       
                              break;
                         default:
                              return `<label>${lbl}</label>
                                   <div class="form-group ">
                                        <div class="pj-form-data font-size-lg font-weight-semibold" payload="${params.field}"></div>${helperText}
                                   </div>`;                       
                    }

               }
          },
          "pj-data" : {       // a dom hold data obj
               layout : (params) => {
                    return ` <div type="data" class="pj-form-data d-none" payload="${params.field}"></div>`;
               }
          },
          "pj-textbox" : {
               layout : (params) => {
                         let helperText = "";

                         let textboxtype = params.type || "text";

                         if (params.helper) {
                              helperText = `<span class="form-text text-muted font-size-sm">${params.helper}</span>`;
                         }

                         let labeltext = params.label ? `<label>${params.label}</label>` : "";

                         switch (params.type) {
                              case "hidden":
                                   return ` <input type="hidden" class="pj-form-data" payload="${params.field}">`;
                              case "email":
                                   return `${labeltext}
                                             <div class="form-group form-group-feedback form-group-feedback-right">
                                                  <input ${params.option} type="email" class="form-control form-group-control pj-form-data" payload="${params.field}">
                                                  ${helperText}<div class="form-control-feedback form-control-feedback-lg hide">
                                                       <i class="icon-reset"></i>
                                                  </div>
                                             </div>`;
                              case "datetime":
                                   return `${labeltext}
                                             <div class="form-group form-group-feedback form-group-feedback-right for-datetime-picker ">
                                                  <input ${params.option} type="datetime-local" step=1 class="form-control form-group-control pj-form-data" payload="${params.field}">
                                                  ${helperText}<div class="form-control-feedback form-control-feedback-lg space-for-picker hide">
                                                       <i class="icon-reset"></i>
                                                  </div>
                                             </div>`;  
                              case "button":
                                   var button_label = $(params.ctx).attr("button_label") || "";

                                   return `${labeltext}
                                             <div class="form-group input-group">
                                                  <input ${params.option} type="text" class="form-control form-group-control pj-form-data" payload="${params.field}">
                                                     
                                                       <span class="input-group-append">
                                                            <button class="btn btn-light pj-form-data pj-form-action" type="button" payload="${params.action}" data-field="${params.field}">${button_label}</button>
                                                       </span>     
                                                       ${helperText}
                                                     
                                             </div>`;   
                                             
                              default:
                                   return `${labeltext}
                                             <div class="form-group form-group-feedback form-group-feedback-right">
                                             <input ${params.option} type="${textboxtype}" class="form-control form-group-control pj-form-data" payload="${params.field}">
                                             ${helperText}<div class="form-control-feedback form-control-feedback-lg hide">
                                                  <i class="icon-reset"></i>
                                             </div>
                                        </div>`;
                         }
                  }
          },
          "pj-password" : {
               layout : (params) => {
                         return `<label>${params.label}</label>
                              <input ${params.option} type="password" match-with="${params.type}"
                              placeholder="******" 
                              class="form-control pj-form-data" payload="${params.field}">`;
                  }
          },
          "pj-password-confirm" : {
               layout : (params) => {
                         return `<label>${params.label}</label>
                              <input ${params.option} type="password" 
                              placeholder="******" 
                              class="form-control pj-form-data" >`;
                  }
          },

          "pj-radio" : {      // requrie field, type, label
               layout : (params) => {
                    return `<div class="custom-control custom-radio mb-2">
                              <input  ${params.option} type="radio" class="custom-control-input pj-form-data" pj-value="${params.type}" payload="${params.field}" name="${params.field}" id="${params.field}_radio_${params.type}" >
                              <label class="custom-control-label" for="${params.field}_radio_${params.type}">${params.label}</label>
                         </div> `;
               }
          },

          "pj-checkbox" : {
               layout : (params) => {

                    var helperText = "";
                    if (params.helper) {
                         helperText = `<div class="text-muted font-size-sm">${params.helper}</div>`;
                    }

                    switch (params.type) {
                         case "switch":
                              return `
                                   <label class="custom-control custom-control-right custom-switch mb-2 mt-2">
                                        <input ${params.option} type="checkbox" class="custom-control-input pj-form-data pj-form-action" payload="${params.field}">
                                        <span class="custom-control-label position-static">
                                             ${params.label}
                                             ${helperText}
                                        </span>
                                       
                                   </label>`;                              
                         default:
                              return `
                              <label class="custom-control custom-checkbox mb-1 mt-1">
                                   <input ${params.option} type="checkbox" class="custom-control-input pj-form-action pj-form-data" payload="${params.field}">
                                   <span class="custom-control-label">
                                        ${params.label}${helperText}
                                   </span>
                              </label>`;
                    }
                    
               }
          },          
          "pj-checkbox-group" : {
               layout : (params) => {

                    var ctx = params.type || "";       // define a list for item in csv format
                    var items = ctx.split(",");                    
                    var chk = [];

                    for (idx in items) {
                         chk.push(`<div class="custom-control custom-checkbox custom-control-inline mb-2">
                                   <input ${params.option} payload="${params.field}_${idx}" type="checkbox" class="custom-control-input pj-form-data" id="${params.field}_chkbox_${idx}" >
                                   <label class="custom-control-label" for="${params.field}_chkbox_${idx}">${items[idx]}</label>
                              </div>          
                         `);
                    }

                    return `<div class="form-group"><p>${params.label}</p>
                              <div class="border p-2 rounded">
                                  ${chk.join("")}       
                              </div></div>`;
               }
          },
         
          "pj-textarea" : {
               layout : (params) => {

                         let helperText = "";                         
                    
                         if (params.helper) {
                              helperText = `<span class="form-text text-muted font-size-sm">${params.helper}</span>`;
                         }

                         return `<label>${params.label}</label>
                                   <div class="form-group form-group-feedback form-group-feedback-right">
                                   <textarea ${params.option} row=4 class="form-control form-group-control pj-form-data" payload="${params.field}">
                                   </textarea> ${helperText}
                                   <div class="form-control-feedback form-control-feedback-lg hide">
                                        <i class="icon-reset"></i>
                                   </div>
                              </div>`;

                  }
          },
          "pj-button" : {
               layout : (params) => {             
                    
                    var icon = $(params.ctx).attr("icon");

                    switch (params.type) {
                         // style in ladda button
                         case "spinner":
                              return `<button type="button" nosave 
                                   class="btn btn-primary btn-ladda btn-ladda-spinner ladda-buttonpj-form-data pj-form-action ${params.option}" 
                                   payload="${params.action}" 
                                   data-field="${params.field}" data-style="expand-left" 
                                   data-spinner-color="#FFF" data-spinner-lines="8" data-spinner-size="20">
                                        <span class="ladda-spinner"></span>
                                        <span class="ladda-label">${params.label}</span>                           
                              </button>`;
                         case "icon":
                              return `<button type="button" nosave class="pj-form-data pj-form-action btn ${params.option}" payload="${params.action}" data-field="${params.field}"><span class='font-size-sm ${icon}'></span> ${params.label}</button>`
                         default :
                              return `<button type="button" nosave class="btn btn-primary pj-form-data pj-form-action ${params.option}" payload="${params.action}" data-field="${params.field}">${params.label}</button>`
                    }

                    
               }
          },


          "pj-pagination" : {
               layout : (params) => {
                    var ctx = layoutTemplates.widgetPagination;
                    return  _.replace(ctx, "{id}", `id="${params.label}"`);
               }
          },
          "pj-pagination-info" : {
               layout : (params) => {
                    var ctx = layoutTemplates.widgetPaginationInfo;
                    return  _.replace(ctx, "{id}", `id="${params.label}"`);
               }
          },
          "pj-multiselect" : {
               layout : (params) =>  {

                    var datakey = $(params.ctx).attr("key") || "";
                    var displayformat = $(params.ctx).attr("format") || "";
                    var lbl = params.label ? `<label>${params.label}</label>` : "";

                    return `${lbl}<select ${params.option} multiple="multiple" dataset="${params.type}" 
                                   datakey="${datakey}" displayformat="${displayformat}"
                                   class="form-control select pj-form-data" payload="${params.field}"></select>`;
               }
          },
          "pj-select" : {
               layout : (params) =>  {

                    var datakey = $(params.ctx).attr("key") || "";
                    var displayformat = $(params.ctx).attr("format") || "";
                    var lbl = params.label ? `<label>${params.label}</label>` : "";


                    return `${lbl}
                              <select ${params.option} dataset="${params.type}" 
                                        datakey="${datakey}" displayformat="${displayformat}"
                                        class="form-control select pj-form-data" payload="${params.field}"></select>`;
               }
          },

          "pj-card" : {
               layout : (params) => {
                    switch (params.type) {                                             
                         default:
                              var ctx = layoutTemplates.widgetCardGeneral;      
                              ctx = _.replace(ctx, "{card-header-elements}",$(params.ctx).find("header").html() );
                              ctx = _.replace(ctx, "{card-content}", $(params.ctx).find("content").html());      
                              ctx = _.replace(ctx, "{card-verb}",params.field );                        
                              return  _.replace(ctx, "{card-header-title}", params.label);
                    }                    
               }
          },


          "pj-header-button" : {
               layout : (params) => {

                    switch (params.type) {
                         case "backindex":
                              return `<div class="d-flex justify-content-center">
                                        <a href="#" class="btn btn-link btn-float text-body pj-form-action" payload="goindex"><i class="icon-enter3"></i><span>Back</span></a>
                                   </div>`;                    
                         case "addnew":
                              return `<div class="d-flex justify-content-center">
                                        <a href="#" class="btn btn-link btn-float text-body pj-form-action" payload="create"><i class="icon-plus3"></i><span>New</span></a>
                                   </div>`;         
                         case "duplicate":
                              return `<div class="d-flex justify-content-center">
                                        <a href="#" class="btn btn-link btn-float text-body pj-form-action" payload="duplicate"><i class="icon-copy4"></i><span>Copy</span></a>
                                   </div>`;                                                          
                    }
               }
          },          

          "pj-table-header" : {
               layout : (params) => {
                    var ctx = layoutTemplates.widgetTableHeader;
                    ctx = _.replace(ctx, "{table-header-id}", `${params.type}`);
                    return  _.replace(ctx, "{table-header-title}", params.label);
               }
          },          

          "pj-table-coltitle" : {
               layout : (params) =>  {

                    if (params.type) {
                         return `<a href="#" class="pj-form-action pj-table-sort-coltitle text-dark ${params.option}"
                                   payload="setTableOrder" type="${params.type}">${params.label}<i class="ml-2"></i></a>`;                              
                    } else {
                         return `<span class="pj-table-sort-coltitle text-dark ${params.option}" >${params.label}</span>`;                              
                    }
               }
          },
          "pj-table-colfilter" : {
               layout : (params) =>  {
                    var lbl = params.label || "Filter...";


                    switch (params.type) {
                         case "reset":
                              return `<button class="btn btn-warning pj-form-action" payload="clearTableFilter" data-filter="" type="button"><i class="icon-cross3"></i></button>`;
                              break;
                         case "selection":   
                              // config option in JSON format                 
                              let out = `<div class="input-group">
                                        <input type="hidden" class="selected-kw">
                                        <select multiple="multiple" class="form-control select pj-table-filter-col-input pj-form-action" payload="setTableFilter" data-filter="${params.field}">`;
                                   
                              let ds =  JSON.parse(params.config);
                              if (ds) {
                                   for (idx in ds) {
                                         out += `<option value="${ds[idx]}">${idx}</option>`;
                                   }
                              }

                              out += `</select></div>`;      
                              return out;
                              break;
                         default:
                              return `<div class="input-group">
                                   <input type="text" class="form-control border-right-0 pj-table-filter-col-input " placeholder="${lbl}">
                                   <span class="input-group-append">
                                        <button class="btn border border-left-0 rounded pj-form-action" payload="clearTableFilter" data-filter="${params.field}" type="button"><i class="icon-cross2 font-size-xs"></i></button>
                                   </span>
                                   <span class="input-group-append hide">
                                        <button class="btn btn-light pj-form-action" payload="setTableFilter" data-filter="${params.field}" type="button"><i class="icon-check2"></i></button>
                                   </span>
                              </div>`;
                    }                 
               }
          },
          "pj-table-pagesize" : {
               layout : (params) =>  {
                    var sizes = params.type || "30";
                    var label = params.label || "#";
                    var out = "";

                    sizes = sizes.split(",");

                    for (var idx in sizes) {
                         var lbl = label.replace("#", sizes[idx]);
                         out += `<a href="#" class="dropdown-item pj-form-action" payload="setpagesize" data-pagesize=${sizes[idx]}>${lbl}</a>`;
                    }

                    return `<div class="dropdown d-inline-flex position-static mr-3">
                                   <a href="#" class="list-icons-item" data-toggle="dropdown" ><i class="icon-eye"></i></a>
                                   <div class="dropdown-menu dropdown-menu-right" style="">${out}</div>
                              </div>`;
                         }
          }


     }


     for (var pjtag in elementTypes) {

          var p = $(container).find(pjtag);

          $.each(p, function(idx, elem){

               var newdom = elementTypes[pjtag].layout({
                    ctx       : elem,
                    label     : $(elem).attr("label") || "",
                    helper    : $(elem).attr("helpertext") || "",
                    field     : $(elem).attr("field") || "",
                    option    : $(elem).attr("option") || "",
                    type      : $(elem).attr("type") || "",
                    action    : $(elem).attr("action") || "",
                    config   : $(elem).attr("config") || ""    
               });

               $(elem).replaceWith(newdom);
          });
     }

     // binding undo action for each elemento
     $(container).find(".pj-form-data").off("change").on("change", function(e){
          $(this).addClass("border-info pj-form-updated");
          toggleUndoOption(this);

          // fire an event for any pj-element changes
          utils.triggerPJEvent("pjFormElementChange",this)
          // utils.log(elmName +' fired');

          
     }).off("reset").on("reset", function(e) {
          $(this).removeClass("border-info pj-form-updated");
          $(this).val($(this).data("defaultvalue"));
          toggleUndoOption(this);
     })

     
     // binding col filter actions    ======
     // for text input
     $(container).find(".pj-table-filter-col-input").off("keyup").on("keyup", function(e){
          var _this = this;
          // get it little delay
          clearTimeout(this.dt);
          this.dt = setTimeout(()=>{
               $(_this).closest(".input-group").find(".pj-form-action[payload=setTableFilter]").trigger("click");
          }, 1000);
     })
     
     // for col filter selection
     $(container).find(".select.pj-table-filter-col-input").off("change").on("change", function(e){
          
          var _this = this;
          var kw = $(this).val()

          clearTimeout(this.dt);
          $(this).closest(".input-group").find(".selected-kw").val(kw);   

          this.dt = setTimeout(()=>{
               $(_this).trigger("click");
          }, 1000);                 
     })
     


}

function toggleUndoOption(target) {

     if ($(target).hasClass("pj-form-updated")){
          $(target).siblings(".form-control-feedback").removeClass("hide").one("click", function(e){
               $(target).trigger("reset");
          });
     } else {
          $(target).siblings(".form-control-feedback").addClass("hide").off("click");
     }
}


//   rev 23.11.17
//   page router
//   params : { 
//        verb (page name)    
//        edit (sn | false)   << if false means no direct edit
//   }
function routePageView(params) {

     var verb = params?.verb || false;
     var keySN = params?.edit || false; // for direct edit
     var res = params?.data || {};

     if (verb) {
          render(verb, res, (target) => {               

               // utils.log("render DONE", verb, keySN);

               // if keySN exist, tigger page edit
               if (keySN) {
                    var eventName = "PageViewEdit@"+verb;

                    // check if event is ready
                    var eventList = $._data(window, "events");

                    if (eventName in eventList) {
                         $(window).trigger(eventName, keySN );
                    } else {
                         // utils.log("defer trigger");
                         // if not exist wait 
                         setTimeout(()=>{
                              $(window).trigger(eventName, keySN);
                         }, 500);                    
                    }
               }
          });
          return true;
     } 
     return false;

}


//   rev 23.11.17
//   add cbfunc
//
//   rev 22.06.16
//   content page view loader
//   load content according to verb from manifest
//
function loadPageView(verb, res, cbfunc) {

     //utils.loaderBlock(true);

     var path = "./verbs/"+verb+"/index.html";
     var target = $(pjLayoutContentID);

     $(target).load(path, function(pagectx, status, xhr){


          if(status === "error") {
               alert("Error: page not defined");
          } else {

               // render all pj-layout element
               renderPJelements(target);

               $(target).data({
                    verb : verb,
                    res : res
               });
               $(window).trigger("PageViewRefresh@"+verb);
               $(window).trigger("PageViewReset@"+verb);    // << reset settings

          }
          if (cbfunc) {
               cbfunc(target);
          }

          utils.loaderBlock(false);

     });

     return "";

}


//   rev 22.06.22
//   render page layout to main body for given verb
//
function render(verb, res, cbfunc) {

     var ctx;
     var pool = $("#pj-content");

     var settings = _.clone(page_manifest_item_tpl);

     var cfg = getPageManifest(verb);
     _.merge(settings, cfg);

     if (cfg !== undefined) {

          if (settings.contents === false) {
               // ctx  = loadPageView(verb, res);
               ctx = "";
          } else if (settings.contents === "EXTERNAL_URL") {
               renderWindow(res.data);
               ctx = false;   // route to dashboard
          } else {
               ctx  = settings.contents(verb, res);
          }

          // if there is nothing for contents, return to dashboard
          if (ctx === false) {

               utils.log("routing to dashboard");
               render("dashboard", "");

          } else {

               var layoutCtx = {
                    header : settings.header || false,
                    headerTitle : (settings.title || verb),
                    contents : ctx
               }

               if (settings.title) {
                    delete layoutCtx.header;
               }

               layout = layoutComposer(layoutCtx, "pj-layout", settings.layout);
               pool.html(layout);

//     utils.log("Query result >>>>>>>>>>>>>>>> ", res);

               if (ctx === "") {
                    loadPageView(verb, res, cbfunc);
               } else {
                    utils.loaderBlock(false);
               }


          }

     } else {
          pool.html("Page settings is not available for "+verb);
     }
}
