// defined all menu categries

export const menu_order = {
     testing : {
          title : "Testing Only",
          disabled : 1

     },
     orders : {                     
          title : "Orders"          
     },     
     lockers : {                     
          title : "Lockers"          
     },
     accounts : {                     
          title : "Accounts"          
     },
     accounting : {                     
          title : "Accounting"          
     },
     costing : {                     
          title : "Costing"          
     },
     service_items : {
          title : "Service Items"
     },
     menuitem : {
          title : "Aux Items"
     },
     user : {
          title : "System Users"
     },
     taskmanager : {          
          title : "Tasks"
     },
     systemutils : {                     
          title : "System Settings",
          icon : "icon-cogs"
     },
     

};

// optional, just overrider default itlte if any

//   title : alias
//   locationID : 0 - mainmenu, 1 - usermenu
//   disabled : 0 | 1 - disabled the menu
//   icon : str - class of icon

export const menu_item_settings = {
     getAllUsers : {
          title : "Users Management"
     },
     userProfile : {
          title : "User Settings",
          locationID : 1
     },
     verbPermissionEdit : {
          disabled : 1
     },
     controlPanel : {
          "icon"   : "icon-cog2",
     }

};
